<template>
  <div class="container">
    <div class="search-date">
      <div
        :class="date == 'month' ? 'active-date' : ''"
        @click="changeDate('month')"
      >
        月历史数据
      </div>
      <div
        :class="date == 'week' ? 'active-date' : ''"
        @click="changeDate('week')"
      >
        周历史数据
      </div>
    </div>
    <div class="charts">
      <VChart :option="chartOption" :autoresize="true" ref="chart" />
      <div>
        <div class="check-box">
          <div v-for="(item, index) in checkOption" :key="index">
            <div class="switch-container">
              <input
                type="checkbox"
                :id="'switch-' + index"
                v-model="item.value"
                class="switch-input"
                @change="changeCheckOption(item.chart)"
              />
              <label :for="'switch-' + index" class="switch-label"></label>
            </div>
            <span>{{ item.name }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VChart from "vue-echarts";
import ecStat from "echarts-stat";
import { nextTick } from "vue";
import { data } from "./data.js";
import { use, registerTransform } from "echarts/core";
import { UniversalTransition, LabelLayout } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import { ScatterChart, LineChart, GraphChart } from "echarts/charts";
import {
  DatasetComponent,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  TransformComponent,
  LegendComponent,
  MarkLineComponent,
} from "echarts/components";
use([
  CanvasRenderer,
  ScatterChart,
  UniversalTransition,
  DatasetComponent,
  LabelLayout,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  TransformComponent,
  LineChart,
  GraphChart,
  MarkLineComponent,
  LegendComponent,
]);
export default {
  components: {
    VChart,
  },
  data() {
    return {
      operateData: [],
      operateData1: [],
      topLineData: [],
      topLineDataSort: [],
      chartOption: {},
      date: "month",
      checkOption: [
        {
          name: "显示散点",
          value: true,
          chart: "scatter",
        },
        {
          name: "显示开度值线性回归",
          value: false,
          chart: "开度值线性回归",
        },
        {
          name: "显示效率值多元线性回归",
          value: true,
          chart: "效率值多元线性回归",
        },
        {
          name: "显示范围",
          value: false,
          chart: "range",
        },
      ],
    };
  },
  created() {
    this.setChartData(data);
    registerTransform(ecStat.transform.regression);
    nextTick(() => {
      // this.chartOption = this.$echarts.init(this.$refs.chart);
      this.chartOption = this.setOption();
    });
  },
  methods: {
    // 处理原数据，生成图表所需数据
    setChartData(arr) {
      this.operateData = arr.reduce((acc, item) => {
        const key = parseInt(item[2]);
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push([item[0], item[1]]);
        return acc;
      }, {});

      // 取数组中的最小值和最大值，并从最小值开始，每隔0.2取一个值，合并成新数组
      const result = data.reduce(
        (acc, item) => {
          if (item[3] > acc.max[3]) {
            acc.max = item;
          }
          if (item[3] < acc.min[3]) {
            acc.min = item;
          }
          return acc;
        },
        { max: data[0], min: data[0] }
      );
      const period = Array.from(
        { length: Math.ceil((result.max[3] - result.min[3]) / 0.2) },
        (_, index) => parseFloat((result.min[3] + index * 0.2).toFixed(2))
      );

      /**
       * @param {*} val  Number
       * 处理数组，基于上面每隔0.2取的值，按照（小于分组值+大于分组值）数量相等来分组
       */
      // const editArr = (val) => {
      //   const { large, small } = data
      //     .sort((a, b) => a[3] - b[3])
      //     .reduce(
      //       (acc, item) => {
      //         if (item[3] >= val) {
      //           acc.large.push(item);
      //         }
      //         if (item[3] <= val) {
      //           acc.small.push(item);
      //         }
      //         return acc;
      //       },
      //       { large: [], small: [] }
      //     );
      //   return [
      //     ...new Set([
      //       ...small.slice(-large.length),
      //       ...large.slice(0, small.length),
      //     ]),
      //   ].map((item) => [item[0], item[1]]);
      // };

      this.operateData1 = period.reduce((acc, item) => {
        // acc[item] = editArr(item);
        // return acc;
        acc[item] = data
          .map((item1) => {
            if (item1[3].toFixed(1) == item.toFixed(1)) {
              return [item1[0], item1[1]];
            }
          })
          .filter((item) => item);
        return acc;
      }, {});
      this.operateData1 = {
        93.0: [
          [580.6, 305.7],
          [559.7, 275.3],
          [570.1, 274.8],
          [579.8, 272.8],
        ],
        92.8: [
          [574.0, 305.8],
          [560, 292],
          [550, 268.3],
          [560, 267.9],
          [579.8, 256.3],
        ],
        92.6: [
          [560.4, 305.5],
          [549.4, 280.8],
          [540.4, 263.3],
          [550.3, 257.6],
          [579.8, 245.3],
        ],
        92.4: [
          [550.4, 305.5],
          [539.4, 280.8],
          [530.4, 263.3],
          [540.3, 247.6],
          [580.8, 235.3],
        ],
        92.2: [
          [540.4, 305.5],
          [529.4, 280.8],
          [520.4, 263.3],
          [530.3, 237.6],
          [580.8, 225.3],
        ],
        92.0: [
          [530.4, 305.5],
          [519.4, 280.8],
          [510.4, 263.3],
          [520.3, 227.6],
          [580.8, 215.3],
        ],

        91.8: [
          [520.4, 305.5],
          [509.4, 280.8],
          [500.4, 263.3],
          [510.3, 217.6],
          [580.8, 205.3],
        ],
        91.6: [
          [510.4, 305.5],
          [499.4, 280.8],
          [490.4, 263.3],
          [500.3, 207.6],
          [580.8, 195.3],
        ],
        91.4: [
          [500.4, 305.5],
          [489.4, 280.8],
          [480.4, 263.3],
          [490.3, 197.6],
          [580.8, 185.3],
        ],
        91.2: [
          [490.4, 305.5],
          [479.4, 280.8],
          [470.4, 263.3],
          [480.3, 187.6],
          [580.8, 175.3],
        ],
      };
      // 范围顶部曲线数据
      this.topLineData = Object.keys(this.operateData)
        .reverse()
        .reduce((acc, key) => {
          if (acc.length) return acc;
          if (this.operateData[key].length >= 2) return this.operateData[key];
          return [];
        }, []);

      this.topLineDataSort = this.topLineData.sort((a, b) => {
        if (a[0] === b[0]) {
          return a[1] - b[1];
        } else {
          return a[0] - b[0];
        }
      });
      console.log("this.operateData", this.operateData);
      console.log("this.operateData1", this.operateData1);
    },

    setOption() {
      let _this = this;
      return {
        dataset: [
          // 散点图数据
          {
            source: data.map((item) => [item[0], item[1]]),
          },
          // 画线性回归线
          ...Object.values(_this.operateData1).map((item) => {
            return {
              source: item,
            };
          }),
          ...Object.values(_this.operateData1).map((item, index) => {
            return {
              fromDatasetIndex: index + 1,
              transform: {
                type: "ecStat:regression",
                config: { method: "polynomial", order: 2 },
              },
            };
          }),
          // // 画多元线性回归曲线
          ...Object.values(_this.operateData).map((item) => {
            return {
              source: item,
            };
          }),
          ...Object.values(_this.operateData).map((item, index) => {
            return {
              fromDatasetIndex:
                Object.values(_this.operateData1).length * 2 + 1 + index,
              transform: {
                type: "ecStat:regression",
                config: {
                  method: "exponential",
                },
              },
            };
          }),
          // // 顶部范围的回归曲线
          {
            source: _this.topLineData,
          },
          {
            fromDatasetIndex:
              Object.values(_this.operateData1).length * 2 +
              Object.values(_this.operateData).length * 2 +
              1,
            transform: {
              type: "ecStat:regression",
              config: { method: "polynomial", order: 2 },
            },
          },
        ],
        title: {
          // text: "水轮机开度回归测试",
          sublink: "https://github.com/ecomfe/echarts-stat",
          left: "center",
        },
        grid: { top: "5%", bottom: "5%" },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
        },
        legend: {
          data: ["scatter", "开度值线性回归", "效率值多元线性回归", "range"],
          show: false,
          selected: {
            scatter: true,
            开度值线性回归: false,
            效率值多元线性回归: true,
            range: false,
          },
        },
        xAxis: {
          splitLine: {
            lineStyle: {
              type: "dashed",
              color: "#394a57",
            },
          },
          axisLine: {
            lineStyle: {
              color: "#b2cedc",
            },
          },
          axisLabel: {
            fontSize: 10,
          },
          min: 480,
          max: 600,
          name: "P*H(m)",
          nameLocation: "end",
          nameGap: 5,
        },
        yAxis: {
          splitLine: {
            lineStyle: {
              type: "dashed",
              color: "#394a57",
            },
          },
          axisLine: {
            lineStyle: {
              color: "#b2cedc",
            },
          },
          axisLabel: {
            fontSize: 10,
          },
          min: 140,
          max: 320,
          name: "P*Pm(MW)",
          nameLocation: "end",
          nameGap: 5,
        },
        series: [
          {
            name: "scatter",
            type: "scatter",
            datasetIndex: 0,
            itemStyle: {
              color: "#00c9f0",
            },
          },
          ...Object.values(_this.operateData1).map((item, index) => {
            return {
              name: "效率值多元线性回归",
              type: "line",
              smooth: true,
              // datasetIndex: Object.values(this.operateData1).length + 1 + index,
              data: item,
              symbolSize: 0.1,
              symbol: "circle",
              lineStyle: {
                color: "#01d8b8",
              },
              label: {
                show: true,
                // fontSize: 10,
                // textBorderWidth: 0,
                // color: "#fff",
                formatter: function (params) {
                  if (params.dataIndex === 3) {
                    return `{customStyle|${
                      Object.keys(_this.operateData1)[index]
                    }%}`;
                  }
                  return "";
                },
                rich: {
                  customStyle: {
                    color: "#fff",
                    fontSize: 10,
                    backgroundColor: "rgba(2, 155, 148, 0.8)",
                    lineHeight: 16,
                    height: 16,
                    width: 40,
                    borderRadius: 4,

                    align: "center",
                  },
                },
              },
              labelLayout: { dx: -10 },
              encode: { label: 2, tooltip: 1 },
            };
          }),
          ...Object.values(_this.operateData).map((item, index) => {
            return {
              name: "开度值线性回归",
              type: "line",
              smooth: true,
              datasetIndex:
                Object.values(_this.operateData).length +
                Object.values(_this.operateData1).length * 2 +
                1 +
                index,
              symbolSize: 0.1,
              symbol: "circle",
              label: {
                show: true,
                fontSize: 16,
                formatter: function (params) {
                  if (params.dataIndex === 0) {
                    return `{customStyle|${
                      Object.keys(_this.operateData)[index]
                    }}`;
                  }
                  // 其他数据点不显示标签
                  return "";
                },
                rich: {
                  customStyle: {
                    color: "#fff",
                    fontSize: 10,
                    backgroundColor: "rgba(2, 155, 148, 0.8)",
                    lineHeight: 16,
                    height: 16,
                    width: 40,
                    borderRadius: 4,

                    align: "center",
                  },
                },
              },
              labelLayout: { dx: -10 },
              encode: { label: 2, tooltip: 1 },
            };
          }),
          {
            name: "range",
            type: "line",
            smooth: true,
            datasetIndex:
              Object.values(_this.operateData1).length * 2 +
              Object.values(_this.operateData).length * 2 +
              2,
            symbolSize: 0.1,
            symbol: "circle",
            labelLayout: { dx: -10 },
            encode: { label: 2, tooltip: 1 },
            lineStyle: {
              color: "#ed7d31",
              type: "solid",
              join: "round",
              width: 2,
            },
            markLine: {
              symbol: "none",
              lineStyle: {
                color: "#ed7d31",
                type: "solid",
                join: "round",
                width: 2,
              },
              data: [
                [
                  { coord: [503, 145] },
                  {
                    coord: [585, 145],
                  },
                ],
                [
                  {
                    coord: [585, 145],
                  },
                  {
                    coord: [
                      this.topLineDataSort[_this.topLineDataSort.length - 1][0],
                      this.topLineDataSort[_this.topLineDataSort.length - 1][1],
                    ],
                  },
                ],
                [
                  { coord: [503, 145] },
                  {
                    coord: [
                      _this.topLineDataSort[0][0],
                      _this.topLineData[0][1],
                    ],
                  },
                ],
              ],
            },
          },
          // {
          //   type: "graph",
          //   coordinateSystem: "cartesian2d",
          //   symbolSize: 10,
          //   edgeSymbolSize: [4, 11],
          //   itemStyle: {
          //     color: "red",
          //   },
          //   edgeSymbol: ["circle", "arrow"],
          //   data: [
          //     [503, 153],
          //     [516, 175],
          //     [510, 185],
          //     [495, 165],
          //   ],
          //   links: [
          //     { source: 0, target: 1 },
          //     { source: 1, target: 2 },
          //     { source: 2, target: 3 },
          //     { source: 3, target: 4 },
          //   ],
          //   lineStyle: {
          //     width: 2,
          //     color: "red",
          //   },
          // },
        ],
      };
    },

    changeDate(date) {
      this.date = date;
    },

    changeCheckOption(val) {
      this.$refs.chart.chart.dispatchAction({
        type: "legendToggleSelect",
        name: val,
      });
    },
  },
};
</script>

<style scoped>
.container {
  height: 100%;
  width: 100%;
  background: url("../../src/assets/bg.png") no-repeat 0 0 / 100% 100%;
}
.search-date {
  display: flex;
  justify-content: center;
  color: #fff;
  font-size: 12px;
  font-style: italic;
}
.search-date > div {
  background: url("../../src/assets/tab-n.png") no-repeat 0 0 / 100% 100%;
  width: 134px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  cursor: pointer;
  margin-top: 20px;
}
.search-date > div:nth-child(2) {
  margin-left: 20px;
}
.active-date {
  background: url("../../src/assets/tab-p.png") no-repeat 0 0 / 100% 100% !important;
}
.check-box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.check-box > div {
  display: flex;
  align-items: center;
  margin-left: 20px;
  color: #afcefe;
  font-size: 11px;
}

.switch-container {
  position: relative;
  width: 34px;
  height: 18px;
  margin-right: 4px;
}

.switch-input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-label {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #494949;
  transition: 0.4s;
  border-radius: 34px;
}

.switch-label:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 2px;
  bottom: 2px;
  background-color: #9f9f9f;
  transition: 0.4s;
  border-radius: 50%;
}

.switch-input:checked + .switch-label {
  background-color: #104089;
}

.switch-input:checked + .switch-label:before {
  background-color: #4282e4;
  transform: translateX(14px);
}

.charts {
  height: calc(100% - 80px);
  width: 100%;
}
</style>
