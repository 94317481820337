export const data = [
  [520.9, 305.8, 26.8, 90.38],
  [540, 306.2, 23.9, 91.62],
  [529.9, 305.5, 25.2, 91.01],
  [521.2, 276.1, 22.2, 91.89],
  [530.6, 275.7, 20.9, 92.22],
  [540, 274.9, 19.8, 92.5],
  [521.1, 244.8, 18.4, 92.44],
  [550.1, 275.1, 18.8, 92.81],
  [530, 245.3, 17.7, 92.49],
  [539.8, 245, 17, 92.47],
  [549.3, 244.2, 16.2, 92.35],
  [550.1, 305.9, 22.5, 92.08],
  [540.4, 214.4, 14.8, 90.92],
  [510.2, 244.5, 19.5, 92.26],
  [550, 214.8, 14.2, 90.84],
  [559.6, 213.6, 13.7, 90.76],
  [529.8, 214.5, 15.4, 91.15],
  [510, 275.3, 23.8, 91.4],
  [560.3, 183.9, 11.7, 88.91],
  [550.2, 183.5, 12.2, 89.01],
  [560.1, 153, 9.7, 86.49],
  [559.6, 245.1, 15.7, 92.26],
  [540, 183.2, 12.6, 89.12],
  [559.7, 275.3, 18.2, 92.97],
  [539.7, 153.3, 10.6, 86.71],
  [550, 153.4, 10.2, 86.64],
  [570.2, 215.1, 13.1, 90.73],
  [520.9, 214, 15.9, 91.26],
  [530, 183.7, 13.1, 89.25],
  [560, 305.9, 21.2, 92.44],
  [500.4, 245.1, 20.6, 91.84],
  [500, 214.8, 17.5, 91.52],
  [570.1, 274.8, 17.2, 93.1],
  [510, 213.6, 16.7, 91.35],
  [570, 245, 15.1, 92.17],
  [521, 184, 13.7, 89.37],
  [570.1, 183.6, 11.4, 88.77],
  [500.6, 275.8, 25.2, 90.91],
  [489.9, 244.3, 22, 91.49],
  [569.9, 305.3, 20.2, 92.72],
  [580.6, 305.7, 19.2, 92.96],
  [490, 214.3, 18.3, 91.58],
  [579.8, 275.4, 16.6, 93.17],
  [490.1, 184.1, 15.4, 89.84],
  [499.9, 183.2, 14.7, 89.67],
  [579.8, 244.1, 14.5, 92.01],
  [510, 183.9, 14.2, 89.53],
  [490.3, 152.8, 13.2, 86.3],
  [580.4, 214.9, 12.6, 90.63],
  [499.9, 153.6, 12.5, 86.72],
  [510.2, 153.5, 11.9, 86.89],
  [521, 152.9, 11.3, 86.89],
  [580, 183.4, 10.7, 88.68],
  [570.2, 152.9, 9.3, 86.42],
  [579.8, 152.9, 8.9, 86.37],
];
